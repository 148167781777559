/**
* Generated automatically at built-time (2024-10-07T17:14:52.652Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "collecties-buitenkeukens",templateKey: "sites/107-dbafbaa3-36f2-48b1-9998-cbd1309c50b2"};